<template>
  <div class="container">
    <div class="title-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleText',
  props: [
    'title',
  ],
};
</script>

<style scoped lang="scss">
  .title-wrap {
    font-family: $exo-bold;
    line-height: 1.3;
    margin-bottom: 0.5rem;
  }
</style>
