<template>
  <div id="app">
    <div class="container">
<!--      <div class="nav-wrap">-->
<!--        <Navigation v-if="!['Language'].includes($route.name)" :show="true" />-->
<!--      </div>-->
      <button @click="back" class="title-bar">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
        <h1 v-html="post.post_title"></h1>
      </button>
    </div>
    <PDFJSViewer :path="`${path}`" :fileName="`${name}`"/>
<!--    <embed v-bind:src="name" width= "500" height= "375">-->

  </div>
</template>

<script>
import _ from 'lodash';
// import Navigation from '../components/Navigation.vue';
import PDFJSViewer from '../components/PDFJSViewer.vue';

export default {
  name: 'Pdf',
  components: {
    // Navigation,
    PDFJSViewer,
  },

  created() {
    // fetch PDF based of file URL only
    const guides = JSON.parse(localStorage.getItem('guides')).data;
    this.post = _.find(guides, (guide) => parseInt(this.$route.params.id, 10) === guide.ID);
    if (this.post && this.post.file_url && this.post.file_url.url) {
      this.name = this.post.file_url.url;
    }
    // fetch PDF based on URL + specific page number if page number provided
    if (this.$route.params.page) {
      const pageNumber = this.$route.params.page;
      this.name += `#page=${pageNumber}`;
    }
  },
  data() {
    return {
      post: null,
      name: '',
      path: '/lib/pdfjs-2.9.359-legacy-dist/web/viewer.html',
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
 h1 {
   font-size: 16px;
   font-family: $exo-bold;
 }

 .title-bar {
   cursor: pointer;
   background-color: transparent;
   align-items: center;
   width: 100%;
   border: none;
   display: flex;
   padding: 1rem 0;
   svg {
     min-width: 2rem;
     width: 2rem;
   }

   h1 {
     max-width: 90%;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
   }
 }

 .nav-wrap {
   display: none;

   @media(min-width: 992px) {
     display: block;
   }
 }
</style>
