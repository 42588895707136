<template>
  <div v-if="showInstallMessage">
    <div class="container--install">
      <h3>{{ $t('appleInstallCTA.instructionsPrt1') }}<img :src="image" alt="share logo">{{
          $t('appleInstallCTA.instructionsPrt2') }}</h3>
      <button class="clear" @click="clear">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AppleInstallPwa',
  data() {
    return {
      showInstallMessage: false,
      // eslint-disable-next-line global-require
      image: require('../../public/img/logos/appleshare_v2.png'),
    };
  },
  mounted() {
    console.log('Testing for IOS v2');

    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      console.log('Device is Apple');
      this.showInstallMessage = true;
    }
  },
  methods: {
    clear() {
      this.showInstallMessage = false;
    },
  },
};

</script>

<style scoped lang="scss">
.container--install {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #A32F80;
  padding: 1rem 0;
  z-index: 999;
  @include flex-center(column);

  h3 {
    margin: 2.5rem 0;
    color: #FFF;
    text-align: center;
    font-family: $exo-reg;
    width: 90%;

    img {
      width: 1rem;
    }
  }
}

.clear {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 10px;
  background-color: transparent;
  border: transparent;

  svg {
    height: 2rem;
    stroke: white;
  }
}
</style>
