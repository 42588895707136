<template>
  <div class="main">
    <TitleText>{{ $filters.capitaliseFirstLetter($t('settingsPage.titleText')) }}</TitleText>
    <SecondaryBtn
      btnColour="$purple"
      v-on:click="refresh"
    >
      <strong>{{ $filters.capitaliseFirstLetter($t('settingsPage.secondaryBtnText')) }}</strong>
    </SecondaryBtn>
    <div class="container timestamp">
      {{ $filters.capitaliseFirstLetter($t('settingsPage.lastRefreshed', { timestamp })) }}
    </div>
    <div v-if="loading" class="container">
      Refreshing guides...
    </div>
    <div v-if="updated" class="container updated">
      <p> {{ $filters.capitaliseFirstLetter($t('settingsPage.updateSuccess')) }}</p>
    </div>
    <div v-if="error" class="container failed">
      <p>{{ $filters.capitaliseFirstLetter($t('settingsPage.error')) }}</p>
    </div>
  </div>
</template>

<script>
import Guides from '@/js/Guides';
import SecondaryBtn from '../components/SecondaryBtn.vue';
import TitleText from '../components/TitleText.vue';

export default {
  name: 'Settings',
  components: {
    SecondaryBtn,
    TitleText,
  },
  data() {
    return {
      loading: false,
      timestamp: localStorage.timestamp,
      updated: false,
      error: false,
    };
  },
  methods: {
    refresh(e) {
      e.preventDefault();
      this.loading = true;
      Guides.refresh()
        .then(() => {
          this.timestamp = localStorage.timestamp;

          if (localStorage.refreshGuides) {
            this.updated = true;
          } else {
            this.error = true;
          }
        });
      this.loading = false;
    },
  },
  // mounted() {
  //   this.timestamp = localStorage.timestamp;
  // },
};
</script>

<style scoped lang="scss">
.timestamp {
  font-family: $exo-semi-bold;
  font-size: 0.8rem;
  padding-top: 0.5rem;
}

.updated {
  color: green;
  font-family: $exo-semi-bold;
}

.failed {
  color: orangered;
}
</style>
