<template>
  <div class="chip">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Chip',
};
</script>

<style scoped lang="scss">
.chip {
  color: #fff;
  background-color: $purple;
  padding: 0.5rem 1.5rem;
  border-radius: 10rem;
  font-family: $exo-bold;
  font-size: 0.7rem;
  margin: .2rem .2rem;
}
</style>
