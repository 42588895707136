<template>
  Your browser is not supported.
</template>

<script>
export default {
  name: 'UnsupportedBrowser',
};
</script>

<style scoped>

</style>
