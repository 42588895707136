<template>
  <div class="dropdown">
    <button v-html="selected" @click="open = !open" v-bind:class="{'open': open}"
            class="toggle"></button>
    <div class="options" v-if="open">
      <button class="option" v-for="filter in filters" v-html="filter" v-bind:key="filter"
              @click="selectItem(filter)">
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import Guides from '@/js/Guides';

export default {
  name: 'DropdownFilter',
  data() {
    return {
      selected: 'Categories',
      open: false,
    };
  },
  methods: {
    selectItem(filter) {
      this.selected = filter;
      this.$emit('select', this.selected);
      this.open = false;
    },
  },
  mounted() {
    this.filters = Guides.get_categories();

    localStorage.getItem('lang') === 'en' ? this.selected = 'Categories' : this.selected =
      'Categorïau';
  }
};
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  width: 100%;

  @media (min-width: $lg) {
    width: 300px;
  }

  .toggle {
    position: relative;
    background-color: $purple;
    padding: 1.5rem;
    width: 100%;
    text-align: center;
    font-family: $exo-bold;
    color: #fff;
    border: none;
    transition: .3s;

    &:hover{
      @include on-hover($purple);
    }

    &::after {
      content: "";
      transition: .3s all;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      background-size: 95%;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='9' viewBox='0 0 13 9'%3E%3Cpath id='Vector' d='M6.5,0,0,8.147.676,9,6.5,1.7,12.324,9,13,8.147Z' fill='%23fff'/%3E%3C/svg%3E%0A");
    }

    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        transition: .3s all;
        transform: rotate(180deg) translateY(50%);
      }
    }
  }

  .options {
    width: 100%;
    position: absolute;

    .option {
      background-color: $pale-pink;
      padding: 1.5rem;
      width: 100%;
      text-align: center;
      font-family: $exo-bold;
      color: #fff;
      border: none;
      transition: .3s;
      border-radius: 0;

      &:hover{
        @include on-hover($pale-pink);
      }
    }
  }
}

button {
  cursor: pointer;
}
</style>
