<template>
  <!--  <router-link v-if="post.sub_title" v-bind:to="'/pdf/' + post.ID + '/' + post.sub_title_page" class="link">-->
  <router-link v-bind:to=" post.sub_title_page ?
     `/pdf/${post.ID}/${post.sub_title_page}` : `/pdf/${post.ID}`"
               class="link">
    <div class="search-card">
      <span class="title" v-html="post.post_title"></span>
      <div class="meta-data">
        <span class="supporting-text" v-if="post.sub_title"
              v-html="post.sub_title"></span>
        <div class="chips">
          <Chip v-for="category in post.terms" v-bind:key="category.term_id"
                v-model.capitalize="category.name">
            {{ category.name }}
          </Chip>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Chip from '@/components/Chip.vue';

export default {
  name: 'SearchResult',
  components: {
    Chip,
  },
  props: [
    'post',
  ],
};
</script>

<style scoped lang="scss">
.link {
  text-decoration: none;
}

.search-card {
  padding: 1rem;
  background-color: #fff;
  //margin-bottom: 1rem;

  & > * {
    padding-bottom: 0.5rem;
  }

  .title {
    display: block;
    font-family: $exo-bold;
    color: $pink;
  }

  .meta-data {
    display: flex;
    flex-direction: column;

    .supporting-text {
      font-size: 0.8rem;
      font-family: $exo-reg;
      color: $black;
      padding-bottom: 0.5rem;

      @media (min-width: $md) {
        padding-bottom: 0;
        min-width: 40%;
      }
    }

    .chips {
      display: flex;
      flex-wrap: wrap;

      @media (min-width: $md) {
        justify-content: flex-end;
        width: 100%;
      }
    }

    @media (min-width: $md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}
</style>
