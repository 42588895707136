<template>
  <div class="main">
    <TitleText>
      {{ $filters.capitaliseFirstLetter($t('searchPage.titleText')) }}
    </TitleText>
    <SearchBar @typing="typing"></SearchBar>
    <SearchListing :results="results"></SearchListing>
  </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';
import SearchListing from '@/components/SearchListing.vue';
import TitleText from '@/components/TitleText.vue';
import SearchBar from '../components/SearchBar.vue';
import Guides from '@/js/Guides';

export default {
  name: 'Search',
  components: {
    SearchBar,
    SearchListing,
    TitleText,
  },
  data() {
    return {
      query: null,
      results: false,
      sub_headings: null,
    };
  },
  methods: {
    typing(query) {
      this.query = query;
      // let guides = JSON.parse(localStorage.getItem('guides')).data;
        const guides = Guides.get_guides();
      // Search by title
      const results = _.filter(guides, (guide) => {
        guide.sub_title = false;
        guide.sub_title_page = false;
        const title = guide.post_title.toUpperCase();
        return title.includes(query.toUpperCase());
      });
      const subResults = [];

      // Search by sub heading
      _.forEach(guides, (guide) => {
        const headings = guide.sub_sections;
        _.forEach(headings, (page, heading) => {
          const check = heading.toUpperCase().includes(query.toUpperCase());
          if (check) {
            const newGuide = {
              ID: guide.ID,
              post_title: guide.post_title,
              sub_title: heading,
              sub_title_page: page,
              terms: guide.terms,
            };
            subResults.push(newGuide);
          }
        });

        return true;
      });

      this.results = results.concat(subResults);

      return true;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
