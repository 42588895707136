<template>
  <div class="language-container">
    <button
      @click="setLanguage"
    >{{ language }}</button>
  </div>
</template>

<script>

export default {
  name: 'LanguageSelect',
  data() {
    const language = localStorage.getItem('lang') === 'en' ? 'cy' : 'en';
    return {
      language,
    };
  },
  methods: {
    setLanguage() {
      if (localStorage.getItem('lang') === 'en') {
        localStorage.setItem('lang', 'cy');
      } else {
        localStorage.setItem('lang', 'en');
      }
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.language-container {
  display: flex;
  cursor: pointer;
  padding: 0 0.2rem;

  button {
    color: gray;
    transition: .3s;
    background: none;
    border: none;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      color: $purple;
    }
  }
}
</style>
