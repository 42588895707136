<template>
  <iframe height="100%" width=100% :src="`${getFilePath}`" class="pdf-view"></iframe>
  <div style="height: 0" class="scroll-bottom"></div>
</template>

<script>
export default {
  name: 'PDFJSViewer',
  props: {
    fileName: String,
    path: String,
  },
  computed: {
    getFilePath() {
      if (this.fileName !== '') {
        return `${this.path}?file=${this.fileName}`; // given PDF file
        // Below loads on specific page
        // return 'https://staging-nasapi.kinsta.cloud/wp-content/uploads/2021/07/NAS-LJW-Practice-Guide_W.pdf#page=2';
      }
      return this.path;
    },
  },
  // mounted() {
  //   const element = document.querySelector('.scroll-bottom');
  //   element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // },
};
</script>
<style scoped lang="scss">

div {

  iframe {
    border: none;
    height: calc(100vh - 71.44px);

    @media(min-width: 992px) {
      height: 87vh;
    }
  }
}
</style>
