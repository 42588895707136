<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
       :stroke="colour">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
  props: {
    colour: {
      type: String,
      default: 'white',
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
svg {
  width: 25px;
  height: 25px;
  padding: 10px;
  transition: .3s;
  @include on-icon-hover;
}
</style>
