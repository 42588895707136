<template>
  <div class="card">
    <div class="card-content">
      <div class="meta">
        <router-link v-bind:to="'/pdf/' + id">
          <span class="title" v-html="title"></span>
        </router-link>
        <div v-if="category" class="chips">
          <Chip v-for="category in cat" v-bind:key="category.term_id"
                v-model.capitalize="category.name">{{
              category.name
            }}
          </Chip>
        </div>
        <div v-else></div>
      </div>
      <router-link v-bind:to="'/pdf/' + id">
        <figure class="img-container">
          <img v-bind:src="image" alt="Guide front" class="image">
        </figure>
      </router-link>
    </div>
  </div>
</template>

<script>
import Chip from '@/components/Chip.vue';

export default {
  name: 'Card',
  props: [
    'title',
    'category',
    'image',
    'id',
  ],
  components: {
    Chip,
  },
  computed: {
    cat() {
      // console.log(this.category);

      if (typeof this.category === 'object') {
        return this.category;
      }

      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  max-width: 20rem;
  height: 100%;
  margin: 1rem 1rem;

  @media (min-width: $md) {
    margin: 2rem 1rem;
  }
  @media (min-width: $md) {
    min-width: 20rem;
  }

  a {
    text-decoration: none;
  }

  .card-content {
    height: 100%;

    .meta {
      //min-height: 90px;
      //margin-bottom: 1rem;

      .title {
        color: $pink;
        font-family: $exo-bold;
        margin-bottom: 1rem;
      }

      .chips {
        display: flex;
        flex-wrap: wrap;
        margin: 0.5rem 0;
        height: 100%;
      }
    }

    .img-container {
      width: 100%;
      height: 100%;
      margin: 0;
    }

    .image {
      object-fit: contain;
      width: 100%;
      @include box-shadow;
    }
  }
}
</style>
