<template>
  <div class="container">
    <input type="text" v-model="searchInput" v-bind:placeholder="$t('searchBar.placeholder')"
           aria-label="search documents"
           @keyup="searchFilter">
    <!-- eslint-disable -->
    <button v-on:click="searchFilter">
      <SearchIcon colour="white"></SearchIcon>
    </button>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import SearchIcon from './partials/SearchIcon.vue';

export default {
  name: 'SearchBar',
  components: {
    SearchIcon,
  },
  emits: ['typing'],
  data() {
    return {
      searchInput: '',
    };
  },
  methods: {
    searchFilter(e) {
      e.preventDefault();
      // console.log(this.searchInput);

      this.$emit('typing', this.searchInput);
    },
  },
};
</script>

<style scoped lang="scss">
div {
  display: flex;
  padding-bottom: 1rem;
}

input {
  width: 100%;
  border: 1px solid $purple;
  padding: 1rem;
  font-size: 16px;
  font-family: $exo-reg;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

button {
  background-color: $purple;
  width: 3.5rem;
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  &:hover {
    cursor: pointer;
    @include on-hover($purple);
  }

  >svg {
    &:hover {
      stroke: white;
    }
  }
}
</style>
