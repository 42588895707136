<template>
<!--  <div class="container">-->
    <a v-bind:href="link" class="btn-primary">{{
        $t('primaryBtn.primaryBtnText')
      }}</a>
<!--  </div>-->
</template>

<script>
export default {
  name: 'PrimaryBtn',
  props: [
    'link',
  ],
};
</script>

<style scoped lang="scss">
div {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.btn-primary {
  color: #fff;
  text-decoration: none;
  padding: .7rem 1rem;
  background-color: $orange;
  transition: .3s;
  font-family: $exo-bold;
  font-size: .8rem;
  text-transform: uppercase;
  border-radius: 4px;
  display: flex;
  align-items: center;

  &:hover {
    @include on-hover($orange);
  }
}
</style>
