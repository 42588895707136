<template>
  <div class="container">
    <button>
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SecondaryBtn',
  props: [
    'btnColour',
  ],
};
</script>

<style scoped lang="scss">
button {
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 4rem;
  padding: 1rem 0 0 1rem;
  width: 100%;
  transition: .3s;
  text-align: center;
  font-family: $exo-semi-bold;
  color: #fff;
  border: none;
  background-color: $purple;
  border-radius: 4px;

  &:hover {
    @include on-hover($purple);
  }
}

@media (min-width: 992px) {
  button {
    width: 300px;
  }
}
</style>
