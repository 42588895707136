<template>
  <div class="wrap">
    <div class="container" v-for="(result, i) in results" v-bind:key="`${i}-${result.ID}`">
      <SearchResult :post="result"></SearchResult>
      <div v-if="!has_results">
      </div>
    </div>
  </div>
</template>

<script>
import SearchResult from '@/components/SearchResult.vue';

export default {
  name: 'SearchListing',
  props: [
    'results',
  ],
  components: {
    SearchResult,
  },
  computed: {
    has_results() {
      // console.log(this.results);

      if (!this.results) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped lang="scss">
body {
  margin: 0;
}
.wrap {
  background: linear-gradient(180deg, rgba(255, 71, 50, 0.8) 0%, rgba(216, 59, 105, 0.8) 47%,
    rgba(174, 47, 153, 1) 100%);
  min-height: calc(100vh - 74px);
  min-width: 100%;
  margin: 0;
  padding-bottom: 1rem;
}

.container {
  padding-top: 1rem;
}
</style>
