<template>
  <a :href="url + 'home'">
    <button class="langBtn">
      <slot></slot>
    </button>
  </a>
</template>

<script>
export default {
  name: 'LangButton',
  props: [
    'url',
  ],
};
</script>

<style scoped lang="scss">
.langBtn {
  height: 4rem;
  min-width: 200px;
  transition: .3s;
  text-align: center;
  font-family: $exo-semi-bold;
  color: #fff;
  border: none;
  background-color: $purple;
  margin: 1rem 2rem;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  &:hover {
    @include on-hover($purple);
  }
}
</style>
