<template>
  <div v-if="!installed && !cleared">
    <div class="container--install">
      <h2>{{
          $t('installCTA.offlineInfo')
        }}</h2>
      <button class="button--install" @click="install">{{
          $t('installCTA.cta')
        }}
      </button>
      <button class="clear" @click="clear">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>

import Cookies from 'js-cookie';

export default {
  name: 'InstallPwa',
  data() {
    return {
      event: null,
      installed: false,
      cleared: true,
    };
  },
  mounted() {
    this.captureEvent();

    if (Cookies.get('cleared')) {
      this.cleared = true;
      // eslint-disable-next-line no-restricted-globals
    }

    // let displayMode = 'browser';
    const mqStandAlone = '(display-mode: standalone)';
    this.installed = window.matchMedia(mqStandAlone).matches;
  },
  methods: {
    install() {
      Cookies.set('cleared', true);
      console.log(window.installPrompt);
      window.installPrompt.prompt();
    },
    captureEvent() {
      console.log('capping');
      window.addEventListener('beforeinstallprompt', (e) => {
        // ! Prevent Chrome 67 and earlier from automatically showing the prompt
        Cookies.remove('cleared');
        this.cleared = false;
        console.log('capped');
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    clear() {
      this.cleared = true;
      Cookies.set('cleared', true);
    },
  },
};
</script>

<style scoped lang="scss">
.container--install {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #A32F80;
  padding: 1rem 0;
  z-index: 999;
  @include flex-center(column);

  h2 {
    margin: 2.5rem 0;
    color: #FFF;
    text-align: center;
    font-family: $exo-reg;
    width: 90%;
  }
}

.button {
  &--install {
    border: none;
    background-color: #FFF;
    color: #000;
    padding: 1rem 3rem;
    font-size: 1.2rem;
    border-radius: 4px;
    font-family: $exo-bold;
    width: 85%;
    max-width: 21rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.clear {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 10px;
  background-color: transparent;
  border: transparent;

  svg {
    height: 2rem;
    stroke: white;
  }
}
</style>
