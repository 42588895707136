/**
 * Append PDF file to cache.
 * @param file
 * @returns {Promise<void>}
 */

async function cacheFile(file) {
  const cache = await caches.open('data-cache');
  console.log(`cahcing file... ${file}`);
  cache.add(file);
  const pdfCache = await caches.open('data-cache');
  pdfCache.add(`/lib/pdfjs-2.9.359-legacy-dist/web/viewer.html?${file}`);
  pdfCache.add('/lib/pdfjs-2.9.359-legacy-dist/web/viewer.html');
}

export default cacheFile;
