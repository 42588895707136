<template>
  <div v-if="show">
    <nav class="navigation">
      <router-link to="/home" class="navigation__logo-wrapper">
        <img class="navigation__logo" alt="Vue logo" src="../../public/img/logos/logo-orig.svg">
      </router-link>
      <PrimaryBtn link="/home" class="btn-desktop" v-if="$route.name!=='Home'" />
      <div class="nav-wrap">
        <div class="nav-options">
          <PrimaryBtn link="/home" class="btn-mobile" v-if="$route.name!=='Home'" />
          <LanguageSelect class="language-select"></LanguageSelect>
          <router-link to="/search" class="link">
            <SearchIcon class="searchIcon" colour="gray"></SearchIcon>
          </router-link>
          <router-link to="/settings" class="link">
            <SettingsLink></SettingsLink>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import SettingsLink from '@/components/SettingsLink.vue';
import SearchIcon from './partials/SearchIcon.vue';
import LanguageSelect from './LanguageSelect.vue';
import PrimaryBtn from './PrimaryBtn.vue';

export default {
  name: 'Navigation',
  components: {
    SearchIcon,
    SettingsLink,
    LanguageSelect,
    PrimaryBtn,
  },
  props: [
    'show',
  ],
};
</script>

<style scoped lang="scss">
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  flex-wrap: wrap;

  &__logo-wrapper {
    margin-bottom: 1rem;
    width: 100%;
    justify-content: center;

    @media (min-width: $sm) {
      margin-bottom: 0;
      justify-content: start;
      width: auto;
    }
  }

  .btn-mobile {
    @media (min-width: $sm) {
      display: none;
    }
    @media (min-width: $md) {
      display: flex;
    }
  }

  .btn-desktop {
    display: none;
    @media (min-width: $sm) {
      display: flex;
    }
    @media (min-width: $md) {
      display: none;
    }
  }

  .nav-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: $md) {
      width: auto;
      justify-content: end;
    }

    .language-select {
      margin-right: 0.5rem;
      font-family: $exo-semi-bold;
    }

    .link {
      display: flex;
      align-items: center;
    }

    .nav-options {
      display: flex;
      justify-content: end;
      a {
        margin-right: 1rem;
      }
    }
  }
}

@media (max-width: $sm) {
  .navigation {
    flex-wrap: wrap;
    justify-content: normal;
  }

  .nav-wrap {
    justify-content: center;
    width: 100%;
  }
}
</style>
